<template>
  <div class="login">
    <img class="top_bgc" :src="imgUrl" />

    <div class="form_box">
      <van-form @submit="onSubmit">
        <van-field
          v-model="phone"
          placeholder="请输入手机号"
          maxlength="11"
          :rules="[
            {
              validator: isCellPhone,
              required: true,
            },
          ]"
          :error-message="tel_err"
        >
          <template v-slot:left-icon>
            <div class="icon_box">
              <i class="iconfont icon-shoujihao"></i>
            </div>
          </template>
        </van-field>

        <van-field
          v-model="password"
          type="password"
          placeholder="请输入密码"
          :rules="[{ required: true }]"
        >
          <template v-slot:left-icon>
            <div class="icon_box">
              <i class="iconfont icon-mima"></i>
            </div>
          </template>
        </van-field>

        <div class="login_btn">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
// import { getData } from "@/api/user";
import { postData } from "@/api/user";
import { getAdminTokens, setAdminTokens, _debounce } from "@/libs/util";

export default {
  data() {
    return {
      phone: "13913900000",
      password: "123456",
      tel_err: "",
      imgUrl:
        "https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202106/22/Lark20210623-141114.png",
    };
  },
  created() {
    let token = getAdminTokens();
    if (token) {
      this.$router.push({ name: "admin_binding" });
    }
  },
  methods: {
    onSubmit: _debounce(function () {
      postData({
        url: `api/maintain/login`,
        data: {
          phone: this.phone,
          pass_word: this.password,
        },
      }).then((res) => {
        let data = res.data;
        console.log(data);
        if (data.code === 200) {
          setAdminTokens(data.data.jwt_token);
          this.$toast(data.data.msg);
          this.$router.push({ name: "admin_binding" });
        } else if (data.code === 422) {
          this.$toast(data.data.msg);
        }
      });
    }),
    //检查手机号
    isCellPhone(val) {
      if (!val.trim()) {
        this.tel_err = "手机号不能为空";
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(val)) {
        this.tel_err = "手机号格式错误";
        return false;
      } else {
        this.tel_err = "";
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  min-height: 100vh;
  background-color: #fff !important;
}

.top_bgc {
  margin-bottom: 115px;
  width: 100%;
  height: 194px;
  background: #ffffff;
}

.form_box {
  padding: 0 30px;

  .icon_box {
    margin-right: 20px;
    color: #8e8e8e;
  }

  .login_btn {
    margin-top: 98px;

    button {
      // width: 351px;
      width: 100%;
      height: 44px;
      color: #fff;
      font-size: 18px;
      background: #3dc48d;
      border-radius: 22px;
      border: none;
    }
  }
}
</style>